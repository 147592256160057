import React from 'react';
import styled from 'styled-components';

export const AllColumns = {
  patientInfo: [
{ title: 'Kort kodnummer', key: 'kort_kodnummer' },
{ title: 'Efternamn', key: 'efternamn' },
{ title: 'Ålder vid inklusion', key: 'alder' },
{ title: 'Adress', key: 'adress' },
{ title: 'Postnummer', key: 'postnummer' },
{ title: 'Ort', key: 'ort' },
{ title: 'Telefon mobil', key: 'telefon_mobil' },
{ title: 'Telefon bostad', key: 'telefon_bostad' },
{ title: 'Mejl', key: 'mejl' },
{ title: 'Lösenord', key: 'password' },
{ title: 'Extra bevakning datum', key: 'extra_bevakning1' },
  ],
  inklusion: [
{ title: 'Brev 1 skickat/lämnat datum', key: 'brev1_skickat' },
{ title: 'Patienten exkluderad datum', key: 'excluderad' },
{ title: 'Exkluderad (orsak)', key: 'excluderad_reason' },
{ title: 'Patienten avbryter studien datum', key: 'avbryter_studien' },
{ title: 'Avbryter studien (orsak)', key: 'avbryter_studien_reason' },
{ title: 'Patienten är avliden (datum)', key: 'avliden_datum' },
{ title: 'Telefonsamtal A datum', key: 'telefonsamtal_a' },
{ title: 'Telefonsamtal A svar', key: 'telefonsamtal_a_tackat' },
{ title: 'Telefonsamtal A (orsak)', key: 'telefonsamtal_a_reason' },
{ title: 'Telefonsamtal B datum', key: 'telefonsamtal_b' },
{ title: 'Telefonsamtal B svar', key: 'telefonsamtal_b_tackat' },
{ title: 'Telefonsamtal B (orsak)', key: 'telefonsamtal_b_reason' },
{ title: 'Inklusionsmöte datum', key: 'inklusionsmote' },
{ title: 'Enkät format', key: 'enkat' },
{ title: 'App format', key: 'app' },
{ title: 'Faeceskit 1-4 + remiss skickat datum', key: 'faeceskit1_4_skickat' },
{ title: 'Faeceskit 5 + remiss skickat datum', key: 'faeceskit5_skickat' },
{ title: 'Skriftligt samtycke svar', key: 'samtycke' },
{ title: 'Samtycke lämnat datum', key: 'samtycke_lamnat' },
{ title: 'Samtycke format', key: 'samtycke_format' },
{ title: 'Patient mottagit totalt antal förpackningar', key: 'totalt_forpackningar' },
{ title: 'Inskriven i Melior datum', key: 'melior_datum' },
  ],
  stralbehandling: [
{ title: 'Nybesök datum', key: 'nybesok' },
{ title: 'Förberedande besök datum', key: 'forberedande_besok' },
{ title: 'Strålstart datum', key: 'stralstart' },
{ title: 'Planerat strålslut datum', key: 'planerat_stralslut' },
{ title: 'Definitivt strålslut datum', key: 'stralslut' },
{ title: 'Genomgått ny behandling för cancer före enkät 3', key: 'behandling_fore_enkat3' },
{ title: 'Cellgifter behandling före enkät 3', key: 'behandling_fore_enkat3_cellgifter' },
{ title: 'Annan behandling före enkät 3', key: 'behandling_fore_enkat3_annat' },
  ],
  blodprover: [
{ title: 'Blodprov 1 (baseline) planerat datum', key: 'blodprov1_planerat' },
{ title: 'Blodprov 1 (baseline) utfört datum', key: 'blodprov1_utfort' },
{ title: 'Blodprov 2 (strål) planerat datum', key: 'blodprov2_planerat' },
{ title: 'Blodprov 2 (strål) utfört datum', key: 'blodprov2_utfort' },
{ title: 'Blodprov 3 (uppföljning kort) planerat datum', key: 'blodprov3_planerat' },
{ title: 'Blodprov 3 (uppföljning kort) utfört datum', key: 'blodprov3_utfort' },
{ title: 'Blodprov 4 (uppföljning lång) planerat datum', key: 'blodprov4_planerat' },
{ title: 'Blodprov 4 (uppföljning lång) utfört datum', key: 'blodprov4_utfort' },
  ],
  faecesprover: [
{ title: 'Faecesprov 1 (baseline) planerat datum', key: 'faecesprov1_planerat' },
{ title: 'Faecesprov 1 (baseline) utfört datum', key: 'faecesprov1_utfort' },
{ title: 'Faecesprov 1 (baseline) infryst patient datum', key: 'faecesprov1_infryst_patient' },
{ title: 'Faecesprov 1 (baseline) infryst labb datum', key: 'faecesprov1_infryst' },
{ title: 'Faecesprov 1 (baseline) levererat', key: 'faecesprov1_location' },
{ title: 'Faecesprov 2 (strålstart) planerat datum', key: 'faecesprov2_planerat' },
{ title: 'Faecesprov 2 (strålstart) utfört datum', key: 'faecesprov2_utfort' },
{ title: 'Faecesprov 2 (strålstart) infryst patient datum', key: 'faecesprov2_infryst_patient' },
{ title: 'Faecesprov 2 (strålstart) infryst labb datum', key: 'faecesprov2_infryst' },
{ title: 'Faecesprov 2 (strålstart) levererat', key: 'faecesprov2_location' },
{ title: 'Faecesprov 3 (strålslut) planerat datum', key: 'faecesprov3_planerat' },
{ title: 'Faecesprov 3 (strålslut) utfört datum', key: 'faecesprov3_utfort' },
{ title: 'Faecesprov 3 (strålslut) infryst patient datum', key: 'faecesprov3_infryst_patient' },
{ title: 'Faecesprov 3 (strålslut) infryst labb datum', key: 'faecesprov3_infryst' },
{ title: 'Faecesprov 3 (strålslut) levererat', key: 'faecesprov3_location' },
{ title: 'Faecesprov 4 (uppföljning kort) planerat datum', key: 'faecesprov4_planerat' },
{ title: 'Faecesprov 4 (uppföljning kort) utfört datum', key: 'faecesprov4_utfort' },
{ title: 'Faecesprov 4 (uppföljning kort) infryst patient datum', key: 'faecesprov4_infryst_patient' },
{ title: 'Faecesprov 4 (uppföljning kort) infryst labb datum', key: 'faecesprov4_infryst' },
{ title: 'Faecesprov 4 (uppföljning kort) levererat', key: 'faecesprov4_location' },
{ title: 'Faecesprov 5 (uppföljning lång) planerat datum', key: 'faecesprov5_planerat' },
{ title: 'Faecesprov 5 (uppföljning lång) utfört datum', key: 'faecesprov5_utfort' },
{ title: 'Faecesprov 5 (uppföljning lång) infryst patient datum', key: 'faecesprov5_infryst_patient' },
{ title: 'Faecesprov 5 (uppföljning lång) infryst labb datum', key: 'faecesprov5_infryst' },
{ title: 'Faecesprov 5 (uppföljning lång) levererat', key: 'faecesprov5_location' },
  ],
  enkater: [
{ title: 'Enkät 1 planerat datum', key: 'enkat1_planerat' },
{ title: 'Enkät 1 ifylld datum', key: 'enkat1_fyllt' },
{ title: 'Enkät 1 format', key: 'enkat1_format' },
{ title: 'Enkät 2 planerat datum', key: 'enkat2_planerat' },
{ title: 'Enkät 2 ifylld datum', key: 'enkat2_fyllt' },
{ title: 'Enkät 2 format', key: 'enkat2_format' },
{ title: 'Enkät 3 planerat datum', key: 'enkat3_planerat' },
{ title: 'Enkät 3 ifylld datum', key: 'enkat3_fyllt' },
{ title: 'Enkät 3 format', key: 'enkat3_format' },
  ],
  uppfoljningar: [
{ title: 'Återbesök, Toxkontroll datum', key: 'aterbesok1' },
{ title: 'Återbesök 2 datum', key: 'aterbesok2' },
{ title: 'Telefonuppföljning 1 datum', key: 'telefonuppfoljning1' },
{ title: 'Telefonuppföljning 2 datum', key: 'telefonuppfoljning2' },
{ title: 'Telefonuppföljning 3 datum', key: 'telefonuppfoljning3' },
{ title: 'Telefonuppföljning 4 datum', key: 'telefonuppfoljning4' },
{ title: 'Extra telefonuppföljning datum', key: 'extra_telefonuppfoljning' },
  ],
  mobilapp: [
{ title: 'Kost datum', key: 'kost' },
{ title: 'Toabesök med avföring datum', key: 'toabesok_med_avforing' },
{ title: 'Mediciner datum', key: 'mediciner' },
{ title: 'Buksmärta datum', key: 'lackage' },
{ title: 'Gaser datum', key: 'gaser' },
{ title: 'Kapslar datum', key: 'kapslar' },
  ],
  kapslar: [
{ title: 'Patient mottagit totalt förpackningar', key: 'totalt_forpackningar' },
{ title: 'Start intag kapslar datum', key: 'start_kapslar_datum' },
{ title: 'Avslut intag kapslar datum', key: 'avslut_kapslar_datum' },
{ title: 'Rapporterat återstående kapslar (antal förpackningar)', key: 'antal_pasar_rapporterat' },
{ title: 'Rapporterat återstående kapslar datum', key: 'rapporterat_aterstaende_kapslar' },
  ]
}

const Container = styled.div`
`

const LaggTillButton = styled.button`
display: inline-block;
float: right;
position: relative;
background: transparent;
border-radius: 0.5em;
border: none;
margin: 0 0.2em 0em;
font-weight: bold;
font-size: inherit;
cursor: pointer;

&:hover {
  color: blue;
}
`

const ClearButton = styled.button`
display: inline-block;
float: right;
position: relative;
background: transparent;
border-radius: 0.5em;
border: none;
margin: 0 0.2em 0em;
font-weight: bold;
font-size: inherit;
cursor: pointer;

&:hover {
  color: red;
}
`

const ClosePopUpButton = styled.span`
position: absolute;
top: 0.8rem;
right: 1.8rem;
font-size: 2em;
display: inline-block;
vertical-align: top;
font-weight: bold;
cursor: pointer;
`

const PopUpContainer = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.7);
z-index: 100;
`
const PopUp = styled.div`
position: relative;
width: 80%;
height: 90%;
background-color: white;
border-radius: 1rem;
border: 1px solid #aaa;
margin: 1rem auto;
padding: 0.5rem 2rem;
overflow-y: hidden;
`

const LeftCol = styled.div`
display: inline-block;
width: 25%;
min-width: 100px;
height: 100%;
margin: 0;
border-right: 1px solid #aaa;
`

const RightCol = styled.div`
overflow-y: auto;
display: inline-block;
vertical-align: top;
width: 70%;
height: 90%;
margin: 0;
text-align: left;
`

const Tab = styled.div`
text-align: right;
font-weight: 300;
font-size: 1.5rem;
padding: 1rem 1rem 0 0;
cursor: pointer;

& span {
  visibility: hidden;
}

&:hover {
 color: blue;
}

&.active {
  font-weight: bold;
}

&:hover span,
&.active span {
  visibility: visible;
}
`

class Columns extends React.Component {
  state = {
    popUp: false,
    tab: 'Patient info'
  }

  escPress = (event) =>
    event.keyCode === 27 && this.hidePopUp()

  showPopUp = () => {
    this.setState({
      popUp: true
    }, () =>
      document.addEventListener("keydown", this.escPress, false)
    )
  }

  hidePopUp = () => {
    this.setState({
      popUp: false
    }, () =>
      document.removeEventListener("keydown", this.escPress, false)
    )
  }

  render() {
    const { popUp, tab } = this.state
    const { columns, addColumn } = this.props

    const Tabs = [
      'Patient info',
      'Inklusion',
      'Strålbehandling',
      'Blodprover',
      'Faecesprover',
      'Enkäter',
      'Uppföljningar',
      'Mobilapp',
      'Kapslar',
    ].map(t =>
      <Tab
        key={t}
        className={tab === t ? 'active': ''}
        onClick={() => this.setState({tab: t})}
      >{t} <span>→</span></Tab>
    )

    const generateColumnButtons = (availableColumns) => {

      return availableColumns.map(c => {
        let disabled = columns.find(column => column.key === c.key)

        return <div key={c.title}>
          <div className="filter">
              <button
                disabled={disabled}
                className="button"
                onClick={() =>
                    addColumn(c, () => this.hidePopUp())
                }
              >{disabled ? '': '+ '}{c.title}</button>
          </div>
          { c.break && <br /> }
        </div>
      })
    }

    return (
      <Container>
        { columns.length > 0 &&
          <ClearButton
            onClick={() => this.props.resetColumns()}
          >&times; Rensa kolumner</ClearButton>
        }

        <LaggTillButton
          onClick={() => this.showPopUp()}
        >+ Ny kolumn</LaggTillButton>

        { popUp &&
          <PopUpContainer>
            <PopUp>
              <h3>&nbsp;</h3>
              <LeftCol>
                {Tabs}
              </LeftCol>
              <RightCol>
                {{
                  'Patient info': generateColumnButtons(AllColumns.patientInfo),
                  'Inklusion': generateColumnButtons(AllColumns.inklusion),
                  'Strålbehandling': generateColumnButtons(AllColumns.stralbehandling),
                  'Blodprover': generateColumnButtons(AllColumns.blodprover),
                  'Faecesprover': generateColumnButtons(AllColumns.faecesprover),
                  'Enkäter': generateColumnButtons(AllColumns.enkater),
                  'Uppföljningar': generateColumnButtons(AllColumns.uppfoljningar),
                  'Mobilapp': generateColumnButtons(AllColumns.mobilapp),
                  'Kapslar': generateColumnButtons(AllColumns.kapslar),
                }[tab]}
              </RightCol>
              <ClosePopUpButton
                onClick={() => this.hidePopUp()}
              >&times;</ClosePopUpButton>
            </PopUp>
          </PopUpContainer>
        }
      </Container>
    )
  }
}

export default Columns;
